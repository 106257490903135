module.exports = {
  siteTitle: 'Quantum Native Blog',
  siteTitleAlt: 'Quantum Native Blog', // This allows an alternative site title for SEO schema.
  publisher: 'Kenji Kubo', // Organization name used for SEO schema
  siteDescription: 'Quantum Native Blog',
  siteUrl: 'https://quantum-native.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  postsPerHomePage: 7, // Number of posts shown on the 1st page of of the index.js template (home page)
  postsPerPage: 6, // Number of posts shown on paginated pages
  author: 'Kenji Kubo', // Author for RSS author segment and SEO schema
  authorUrl: 'https://quantum-native.com/about/', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@kenjikun__', // Change for Twitter Cards
  shortTitle: 'Quantum Native Blog', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/share.png', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 1024, // Change to the width of your default share image
  shareImageHeight: 1024, // Change to the height of your default share image
  siteLogo: '/logos/share.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#121212', // Used for Offline Manifest
  copyright: 'Copyright © 2020 Kenji Kubo', // Copyright string for the RSS feed
  googleSiteVerification: 'yb9SrhUS0LnIGlfrBI7M8meHA8FP1u2QLdNt0k4W434',
}
